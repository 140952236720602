@import "https://js.arcgis.com/4.28/@arcgis/core/assets/esri/themes/light/main.css";

:root {
    color-scheme: dark
}

* {
    box-sizing: border-box;
}

.content-scroller {
    overflow: auto !important;
}

html {
    background: black;
}

body {
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    height: 100vh;
    width: 100vw;
    background: black;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div#root {
    height: 100%;
    width: 100%;
}

.esri-view {
    --esri-view-outline: unset;
}

.spacer {
    flex: 1;
}


/* MapBox PopUp */
.mapboxgl-popup-content {
    color: black;
    width: 220px;
}

.mapboxgl-popup-close-button {
    color: black;
}

.mapboxgl-ctrl-logo {
    display: none !important;
}
